import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useContext, useState, useEffect } from "react";
import { getAccountBalance } from "../../api";
import { RetrievalPaymentContext } from "../../context/RetrievalPaymentContext";
import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";
import { queryStringToJson } from "../../utils/queryStringToJson";
import { TrustlyFlow } from "../TrustlyFlow";

export function AddBankButton({ paymentType }) {
  const [isLightBoxRendered, setIsLightBoxRendered] = useState(false);

  const { paymentDetails, updatePaymentDetails } = useContext(paymentType === "Retrieval" ? RetrievalPaymentContext : DeferredPaymentContext);
  
  function handleClick() {
    setIsLightBoxRendered(true);
  }

  async function handleBankInfoRetrieval(transactionId) {
    // const accountBalance = await getAccountBalance(transactionId).then(
    //   (response) => response.data
    // );

    const isAccountAlreadyRegistered = paymentDetails.find(account => {
      return account.accountNumber === "6576";
    });

    if (!isAccountAlreadyRegistered) {
      const updatedPaymentDetails = {
        transactionId,
        bankName: "Demo Bank",
        bankId: "200005501",
        accountNumber: "6576",
        accountType: "Demo Checking Account",
        accountBalance: "100.12"
      };
      // const updatedPaymentDetails = {
      //   transactionId,
      //   bankName: accountBalance.account.paymentProvider.name,
      //   bankId: accountBalance.account.providerId,
      //   accountNumber: accountBalance.account.accountNumberLastFour,
      //   accountType: accountBalance.account.name,
      //   accountBalance: accountBalance.amount
      // };

      updatePaymentDetails(updatedPaymentDetails);
    }
  }

  function handleLightBoxClosing() {
    window.Trustly.removeListeners();
    window.Trustly.addPanelListener((command, obj) => {
      if (obj?.type === "new_location") {
        obj.preventDefault();

        if (obj.data.indexOf("#return") === 0) {
          const { transactionId } = queryStringToJson(obj.data);
  
          handleBankInfoRetrieval(transactionId);
        }
        
        setIsLightBoxRendered(false);
      }
    });
  }

  useEffect(() => {
    handleLightBoxClosing();
  }, [])


  return (
    <>
      <button onClick={handleClick} className="inline-flex items-center gap-2 w-full rounded-md border border-neutral-200 bg-white px-4 py-4 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2">
        <PlusCircleIcon color="#4F565F" width={24} />
        <span className="text-sm font-semibold text-neutral-800">
          Add new bank account
        </span>
      </button>

      {isLightBoxRendered && <TrustlyFlow paymentType={paymentType} />}
    </>
  );
}
